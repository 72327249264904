import React from 'react';

import Layout from 'components/layout';
import Container from 'components/container';
import SEO from 'components/seo';

import ContactForm from 'components/contact-form';
import contactFormConfig from 'data/forms/contact-form';

const IndexPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <Container>
      <h1>Contact Us</h1>
      <h2>Constructing your success</h2>
      <ContactForm config={contactFormConfig} />
    </Container>
  </Layout>
);

export default IndexPage;
