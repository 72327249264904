import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styles from './styles.module.scss';

const ContactForm = ({ config }) => {
  const [honeypotValid, setHoneypotValid] = useState(true);
  const [formState, setFormState] = useState({
    mailSent: false,
    error: null
  });

  const {
    successMessage,
    errorMessage,
    fields
  } = config;

  /**
  * @function handleFormSubmit
  * @param event { object } - form event
  * @return void
  */
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (honeypotValid) {
      axios({
        method: 'post',
        url: '/api/contact/index.php',
        headers: { 'content-type': 'application/json' },
        data: formState
      })
        .then((result) => {
          if (result.data.sent) {
            setFormState({
              ...formState,
              error: null,
              mailSent: result.data.sent
            });
          } else {
            setFormState({
              ...formState,
              error: result.data.message,
              mailSent: false
            });
          }
        })
        .catch((error) => {
          setFormState({
            ...formState,
            error: error.response.data.message,
            mailSent: false
          });
        });
    }
  };

  /**
    * @function handleChange
    * @param event { object } - change event
    * @param field { string } - name of the field
    * @return void
    */
  const handleChange = (event, field) => {
    const { value } = event.target;
    setFormState({ ...formState, [`${field}`]: value });
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <form onSubmit={(event) => handleFormSubmit(event)}>
          {/* Honeypot */}
          {/* Setting style in JavaScript to prevent CSS sniffing */}
          <label
            className={styles.firstName}
            style={{
              display: 'none'
            }}
            htmlFor="field-first-name"
          >
            <span className={styles.visuallyhidden}>First name</span>
            <input
              id="field-first-name"
              className="first-name"
              placeholder="First name"
              autoComplete="off"
              tabIndex="-1"
              onChange={(event) => {
                handleChange(event, 'first-name');
                setHoneypotValid(false);
              }}
            />
          </label>
          {fields
            && fields.map((field) => (
              <React.Fragment key={field.id}>
                {field.type !== 'textarea' ? (
                  <>
                    <label
                      className={styles[field.fieldName]}
                      htmlFor={`field-${field.fieldName}`}
                    >
                      <span className={styles.visuallyhidden}>{field.label}</span>
                      <input
                        id={`field-${field.fieldName}`}
                        type={field.type}
                        className={field.className}
                        placeholder={field.placeholder}
                        required={field.isRequired}
                        onChange={(event) => handleChange(event, field.fieldName)}
                      />
                    </label>
                  </>
                ) : (
                  <>
                    <label
                      className={styles[field.fieldName]}
                      htmlFor={`field-${field.fieldName}`}
                    >
                      <span className={styles.visuallyhidden}>{field.label}</span>
                      <textarea
                        id={`field-${field.fieldName}`}
                        className={field.className}
                        placeholder={field.placeholder}
                        required={field.isRequired}
                        onChange={(event) => handleChange(event, field.fieldName)}
                      />
                    </label>
                  </>
                )}
              </React.Fragment>
            ))}
          <div>
            {formState.mailSent && <div className={styles.success}>{successMessage}</div>}
            {formState.error && (
              <div className={styles.error}>
                {errorMessage}
                <br />
                {formState.error}
              </div>
            )}
          </div>
          <input type="submit" value="Submit" />
        </form>
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  config: PropTypes.objectOf(PropTypes.object.isRequired).isRequired
};

export default ContactForm;
