/**
 * Form configuration
 */

const contactFormConfig = {
  title: 'Contact Form 1',
  successMessage: 'Thank you for your message, we will be in contact shortly.',
  errorMessage: 'There has been a problem submitting the form.',
  fields: [
    {
      id: 1,
      label: 'Name',
      fieldName: 'name',
      type: 'text',
      placeholder: 'Your Name',
      isRequired: true,
      className: 'contact-form__name',
    },
    {
      id: 2,
      label: 'Email',
      fieldName: 'email',
      type: 'email',
      placeholder: ' Your Email',
      isRequired: true,
      className: 'contact-form__email',
    },
    {
      id: 3,
      label: 'Message',
      fieldName: 'message',
      type: 'textarea',
      placeholder: 'Write your message here...',
      isRequired: true,
      className: 'contact-form__message',
    },
  ]
};

export default contactFormConfig;
